<template>
    <BaseModal
        :modalWidth="'1020px'"
        :modalMaxHeight="'calc(100vh - 10px)'"
        :class="{'reconcoliation-modal-import_disabled': isOpenSelectModal}"
        class="reconcoliation-modal-import" 
        @close="onCloseModal"
    >
        <template v-slot:modalBody>
            <div class="reconcoliation-modal-import__body">
                <div class="reconcoliation-modal-import__section reconcoliation-modal-import__title">
                    {{ modalTitle }}
                </div>
                <ReconciliationImport 
                    @onCancel="onCloseModal"
                    @onToggleSelectModal="onToggleSelectModal"
                    @updateModalTitle="onUpdateModalTitle"
                />
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../../Base/BaseModal.vue';
import ReconciliationImport from '../ReconciliationImport/ReconciliationImport.vue';
export default {
    name: 'ReconciliationModalImport',
    components: {
        BaseModal,
        ReconciliationImport
    },
    data: () => ({
        isOpenSelectModal: false,
        modalTitle: 'Импорт из файла Excel'
    }),
    methods: {
        onCloseModal() {
            this.$emit('onCloseModal');
        },
        onToggleSelectModal(isOpen = false) {
            this.isOpenSelectModal = isOpen;
        },
        onUpdateModalTitle(title = '') {
            this.modalTitle = title;
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconcoliation-modal-import {
        // ::v-deep .modal__container {
        //     width: 820px;
        // }
        
        &__title {
            font-size: 20px;
            font-weight: 500;
        }

        &__section {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &_disabled {
            height: 0;
        }
    }
</style>