<template>
    <div class="reconciliation-import">
        <div class="reconciliation-import__step">
            <ReconciliationImportAttach
                v-if="isImportStepMap.attach"
                :tabDescription="imporDataDescriptionTab"
                :tabList="importDataTabList"
                :tabIdActive="importDataTabIdActive"
                :file="importDataFile"
                :isLoading="isLoading"
                @onClickTab="onClickTab"
                @onChangeFile="onChangeFile"
            />
            <ReconciliationImportMarking
                v-if="isImportStepMap.marking"
                :tabDescription="imporDataDescriptionTab"
                :tabList="importDataTabList"
                :tabIdActive="importDataTabIdActive"
                :importDates="importDataDates"
                :tableColumns="importDataTableColumns"
                :tableRows="importDataTableRows"
                :markingValues="importDataMarkingValuesFiltered"
                :templates="importDataTemplates"
                :templateIdActive="importDataTemplateIdActive"
                :settings="importDataSettings"
                :fileId="importDataFileId"
                :fileStartIndex="importDataFileStartIndex"
                :fileStartIndexColumn="importDataFileStartIndexColumn"
                @toPrevStep="toPrevStep"
                @onClickTab="onClickTab"
                @onSelectTemplate="onSelectTemplate"
                @onSelectSetting="onSelectSetting"
                @onLazyloadSetting="onLazyloadSetting"
                @onSearchSetting="onSearchSetting"
                @onSelectItemsTable="onSelectItemsTable"
                @onCheckDates="onCheckDates"
                @onClearSelected="onClearSelected"
                @onToggleSelectModal="onToggleSelectModal"
                @deleteTemplate="onDeleteTemplate"
                @saveTemplateEditable="onSaveTemplateEditable"
                @updateFileStartIndex="onUpdateFileStartIndex"
                @updateFileStartIndexColumn="onUpdateFileStartIndexColumn"
            />
            <ReconciliationImportDownload
                v-if="isImportStepMap.download"
                :tabDescription="imporDataDescriptionTab"
                :tabList="importDataTabList"
                :tabIdActive="importDataTabIdActive"
                :tableColumns="importDataTableColumns"
                :tableRows="importDataTableRows"
                :isLoading="isLoading"
                @toPrevStep="toPrevStep"
                @onClickTab="onClickTab"
                @runMatch="onRunMatch"
                @editCell="onEditCellImportDownload"
                @removeRow="onRemoveRowImportDownload"
                @setDifference="onSetDifferenceImportDownload"
            />
            <ReconciliationImportRealtimeSystem @endMatch="endMatch" />
        </div>
        <div class="reconciliation-import__actions">
            <div class="reconciliation-import__actions-block">
                <template v-if="isImportStepMap.marking">
                    <div
                        v-if="importDataIsTemplates"
                        class="reconciliation-import__action"
                    >
                        <BasePreloaderSkeleton v-if="isLoading" />
                        <BaseButton
                            view="simple"
                            @click="onSaveTemplate"
                        >
                            Сохранить изменения
                        </BaseButton>
                    </div>
                    <div class="reconciliation-import__action">
                        <BasePreloaderSkeleton v-if="isLoading" />
                        <BaseButton
                            view="simple"
                            @click="onCreateTemplate"
                        >
                            Создать новый шаблон
                        </BaseButton>
                    </div>
                </template>
            </div>
            <div class="reconciliation-import__actions-block">
                <div class="reconciliation-import__action">
                    <BasePreloaderSkeleton v-if="isLoading" />
                    <BaseButton
                        view="border"
                        @click="onCancel"
                    >
                        Отменить
                    </BaseButton>
                </div>
                <div class="reconciliation-import__action">
                    <BasePreloaderSkeleton v-if="isLoading" />
                    <BaseButton
                        :disabled="isDisabledNext"
                        view="secondary"
                        @click="toNextStep"
                    >
                        {{nextActionName}}
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReconciliationImportAttach from './ReconciliationImportAttach.vue';
import ReconciliationImportMarking from './ReconciliationImportMarking.vue';
import ReconciliationImportDownload from './ReconciliationImportDownload.vue';
import ReconciliationImportRealtimeSystem from './ReconciliationImportRealtimeSystem.vue';
import BaseButton from '../../Base/BaseButton.vue';
import BasePreloaderSkeleton from '../../Base/BasePreloaderSkeleton.vue';
import ServiceReconciliationImport from '../../../services/ServiceReconciliationV2/ServiceReconciliationImport';
import ServiceReconciliationMatch from '../../../services/ServiceReconciliationV2/ServiceReconciliationMatch';
export default {
    name: 'ReconciliationImport',
    components: {
        ReconciliationImportAttach,
        ReconciliationImportMarking,
        ReconciliationImportDownload,
        ReconciliationImportRealtimeSystem,
        BaseButton,
        BasePreloaderSkeleton
    },
    data: () => ({
        isLoading: false,
        isLoadingSearchSetting: false,
        importSteps: ['attach', 'marking', 'download'],
        importStepIndex: 0,
        importData: {
            attach: {
                description: {
                    approved: 'Текст для экрана прикрепления файла предфакта',
                    reconciliation: 'Текст для экрана прикрепления файла сверки'
                },
                tabList: [
                    {
                        id: 'approved',
                        name: 'Предфакт'
                    },
                    {
                        id: 'reconciliation',
                        name: 'Сверка'
                    }
                ],
                tabIdActive: 'approved',
                file: null,
                fileId: '',
                extraData: {}
            },
            marking: {
                description: {
                    approved: 'Текст для экрана разметки файла предфакта',
                    reconciliation: 'Текст для экрана разметки файла сверки'
                },
                tabList: [],
                tabIdActive: '',
                dates: [],
                tableColumns: [],
                tableRows: {},
                markingValues: [],
                templates: [
                    // {
                    //     id: 'tmp1',
                    //     value: 'Шаблон 1'
                    // },
                    // {
                    //     id: 'tmp2',
                    //     value: 'Шаблон 2'
                    // },
                    // {
                    //     id: 'tmp3',
                    //     value: 'Шаблон 3'
                    // }
                ],
                templateIdActive: '',
                settings: [
                    {
                        id: 'cities',
                        subtitle: 'Город',
                        list: [],
                        listIdActive: '',
                        totalCount: 0,
                        page: 1,
                        search: ''
                    },
                    {
                        id: 'suppliers',
                        subtitle: 'Оператор',
                        list: [],
                        listIdActive: '',
                        totalCount: 0,
                        page: 1,
                        search: ''
                    }
                ],
                fileStartIndex: 1,
                fileStartIndexColumn: 0
            },
            download: {
                description: {
                    approved: 'Текст для экрана загрузки размеченного файла предфакта',
                    reconciliation: 'Текст для экрана загрузки размеченного файла файла сверки'
                },
                tabList: [
                    {
                        id: 'found',
                        name: 'Найдено'
                    },
                    {
                        id: 'unfound',
                        name: 'Не найдено'
                    },
                    {
                        id: 'listError',
                        name: 'Ошибки'
                    }
                ],
                tabIdActive: 'found',
                tableColumns: [],
                tableRows: {},
                tableRowsTotalCount: {}
            }
        },
        importDataTableRowsMarkingSelectedBuffer: []
    }),
    computed: {
        importDataByStep() {
            return this.importData[this.importStep] ?? {};
        },
        imporDataDescriptionTab() {
            const description = this.importDataByStep?.description ?? {};
            return String(description[this.importDataTabIdActiveAttach] ?? '');
        },
        importDataTabList() {
            const tabList = this.importDataByStep?.tabList ?? [];
            return tabList.map(tabListItem => ({
                ...tabListItem,
                isTotalCount: this.isImportStepMap.download,
                totalCount: parseInt(this.importDataTableRowsTotalCount[String(tabListItem?.id ?? '')] ?? 0)
            }));
        },
        importDataTabListIds() {
            return this.importDataTabList.map(tab => String(tab?.id ?? ''));
        },
        importDataTabIdActive() {
            return String(this.importDataByStep?.tabIdActive ?? '');
        },
        importDataTabIdActiveAttach() {
            const importDataAttach = this.importData?.attach ?? {};
            return String(importDataAttach?.tabIdActive ?? '');
        },
        importDataTabIdActiveMarking() {
            const importDataMarking = this.importData?.marking ?? {};
            return String(importDataMarking?.tabIdActive ?? '');
        },
        importDataTableColumns() {
            return this.importDataByStep?.tableColumns ?? [];
        },
        importDataTableRows() {
            const tableRowsByList = this.importDataByStep?.tableRows ?? {};
            return tableRowsByList[this.importDataTabIdActive] ?? [];
        },
        importDataTableRowsIds() {
            return this.importDataTableRows.map(row => String(row?.id ?? ''));
        },
        importDataTableRowsUnfound() {
            const tableRowsByList = this.importDataByStep?.tableRows ?? {};
            const rowsUnfound = tableRowsByList?.unfound ?? [];
            return rowsUnfound;
        },
        importDataMarkingValues() {
            return this.importData.marking?.markingValues ?? [];
        },
        importDataMarkingValuesFiltered() {
            return this.importDataMarkingValues.filter(markingValue => !this.importDataTableRowsMarkingSelectedIds.includes(String(markingValue?.id ?? '')));
        },
        importDataTableRowsMarkingSelected() {
            const tableRows = this.importData.marking.tableRows[this.importData.marking.tabIdActive] ?? [];
            const tableRowsMarkingSelected = tableRows.reduce((tableRowsMarkingSelected, row) => {
                const {id: rowId = '', markingValue = []} = row;
                markingValue.forEach(markingValueItem => {
                    const {
                        id: markingValueId = '',
                        type: markingValueType = '',
                        dates: markingValueDates = []
                    } = markingValueItem;
                    if (String(markingValueId) !== '') {
                        let tableRowsMarkingSelectedItem = {
                            rowId: String(rowId),
                            markingValueId: String(markingValueId),
                            type: String(markingValueType)
                        };
                        if (String(markingValueType) === 'multi')
                            tableRowsMarkingSelectedItem.dates = markingValueDates;

                        tableRowsMarkingSelected.push(tableRowsMarkingSelectedItem);
                    }
                })

                return tableRowsMarkingSelected;
            }, []);

            return tableRowsMarkingSelected;
        },
        importDataTableRowsMarkingSelectedIds() {
            const importDataTableRowsMarkingSelectedIds = this.importDataTableRowsMarkingSelected.map(rowMarkingSelected => String(rowMarkingSelected?.markingValueId ?? ''));

            return importDataTableRowsMarkingSelectedIds;
        },
        importDataTableRowsTotalCount() {
            return this.importDataByStep?.tableRowsTotalCount ?? {};
        },
        isImportDataTableRowsMarkingCity() {
            return this.importDataTableRowsMarkingSelectedIds.some(rowMarkingSelectedId => String(rowMarkingSelectedId) === 'city');
        },
        isImportDataTableRowsMarkingSupplier() {
            return this.importDataTableRowsMarkingSelectedIds.some(rowMarkingSelectedId => String(rowMarkingSelectedId) === 'supplier');
        },
        isImportDataTableRowsUnfound() {
            return this.importDataTableRowsUnfound.length > 0;
        },
        importDataFile() {
            return this.importData?.attach?.file ?? null;
        },
        importDataFileId() {
            return String(this.importData?.attach?.fileId ?? '');
        },
        importDataFileExtraData() {
            return this.importData?.attach?.extraData ?? {};
        },
        importDataFileStartIndex() {
            return parseInt(this.importData?.marking?.fileStartIndex ?? 0)
        },
        importDataFileStartIndexColumn() {
            return parseInt(this.importData?.marking?.fileStartIndexColumn ?? 0)
        },
        importDataTemplates() {
            return this.importDataByStep?.templates ?? [];
        },
        importDataIsTemplates() {
            return this.importDataTemplates.length > 0;
        },
        importDataTemplateIdActive() {
            return String(this.importDataByStep?.templateIdActive ?? '');
        },
        importDataTemplateActive() {
            return this.importDataTemplates.find(template => String(template?.id ?? '') === this.importDataTemplateIdActive) ?? {};
        },
        importDataSettings() {
            return this.importDataByStep?.settings ?? [];
        },
        importDataSettingsIds() {
            return this.importDataSettings.map(importDataSetting => String(importDataSetting?.id ?? ''));
        },
        importDataSettingsListIdsActive() {
            return this.importDataSettings.map(importDataSetting => ({
                id: String(importDataSetting?.id ?? ''),
                listIdActive: String(importDataSetting?.listIdActive ?? '')
            }));
        },
        importDataDates() {
            return this.importDataByStep?.dates ?? [];
        },
        isMarkingPrices() {
            return this.importDataTableRowsMarkingSelected.filter(rowMarkingSelected => String(rowMarkingSelected?.type ?? '') === 'multi').length > 0;
        },
        isMarkingCity() {
            return this.getSettingsListIdActive('cities') !== '' || this.isImportDataTableRowsMarkingCity;
        },
        isMarkingSupplier() {
            return this.getSettingsListIdActive('suppliers') !== '' || this.isImportDataTableRowsMarkingSupplier;
        },
        isDisabledNextMap() {
            return {
                attach: this.importDataFile === null,
                marking: !(this.isMarkingPrices && this.isMarkingCity && this.isMarkingSupplier),
                download: this.isImportDataTableRowsUnfound && this.importDataTabIdActiveAttach === 'approved'
            };
        },
        isDisabledNext() {
            return this.isDisabledNextMap[this.importStep] ?? false;
        },
        importStep() {
            return this.importSteps[this.importStepIndex] ?? '';
        },
        isImportStepMap() {
            return {
                attach: this.importStep === 'attach',
                marking: this.importStep === 'marking',
                download: this.importStep === 'download'
            };
        },
        nextActionName() {
            return this.isImportStepMap.download && this.importDataTabIdActiveAttach === 'approved'
                ? 'Загрузить' : 'Далее';
        }
    },
    watch: {
        isImportStepMap() {
            if (this.isImportStepMap.marking || this.isImportStepMap.download) {
                const tab = this.importData?.attach?.tabList?.find(tab => String(tab?.id ?? '') === this.importDataTabIdActiveAttach);
                const tabName = String(tab?.name ?? '');

                this.$emit('updateModalTitle', tabName);
            } else
                this.$emit('updateModalTitle', 'Импорт из файла Excel');

            if (this.isImportStepMap.marking) {
                this.getSettings(this.importDataSettingsIds);
                this.getMarkingValues();
            }
        }
    },
    created() {
		this.$parent.$on('$scrolModal', () => this.$emit('$scrollReconciliationModalImport'));
	},
    methods: {
        setImportData(importDataKey = '', importDataValue = '', importStep = '') {
            const step = importStep === '' ? this.importStep : String(importStep);
            this.importData = {
                ...this.importData,
                [step]: {
                    ...this.importData[step],
                    [importDataKey]: importDataValue
                }
            };
        },
        onChangeFile(file = null) {
            this.attachFile(file);
        },
        async attachFile(file = null) {
            try {
                this.isLoading = true;
                const fileData = await ServiceReconciliationImport.attachFile(file);
                this.attachFileAfter(fileData);
                this.isLoading = false;
            } catch (error) {
                // alert(error);
                this.$notify.dNotify({
                    type: 'error',
                    title: 'Ошибка прикрепления файла',
                    description: error,
                    duration: Infinity,
                    position: 'bottom-right'
                });

                this.isLoading = false;
            }
        },
        attachFileAfter(fileData = {}, isTabIdActiveUpdate = true) {
            ['fileId', 'file', 'extraData'].forEach(importDataKey => this.setImportData(importDataKey, fileData[importDataKey] ?? null));
            ['tabList', 'tableColumns', 'tableRows'].forEach(importDataKey => this.setImportData(importDataKey, fileData[importDataKey] ?? null, 'marking'));

            if (isTabIdActiveUpdate)
                this.setImportData('tabIdActive', String(fileData?.tabIdActive ?? ''), 'marking');
        },
        async getSettings(settingsIds = []) {
            try {
                const settingsData = await ServiceReconciliationImport.getSettings(settingsIds);
                this.getSettingsAfter(settingsData);
            } catch (error) {
                console.log(error);
            }
        },
        getSettingsAfter(settingsData = []) {
            const settings = this.importDataSettings.map(importDataSetting => {
                const {id = ''} = importDataSetting;
                const settingDataIndex = settingsData.findIndex(setting => String(setting?.id ?? '') === String(id));
                let setting = {...importDataSetting};
                if (settingDataIndex !== -1) {
                    const {items = [], totalCount = 0, page = 1} = settingsData[settingDataIndex];
                    setting = {
                        ...setting,
                        list: [...importDataSetting?.list, ...items],
                        totalCount,
                        page
                    };
                }

                return setting;
            });

            this.setImportData('settings', settings);

            this.$nextTick(() => this.isLoadingSearchSetting = false);
        },
        onLazyloadSetting(settingId = '') {
            if (!this.isLoadingSearchSetting)
                this.getSetting(settingId);
        },
        onSearchSetting(searchValue = '', settingId = '') {
            this.isLoadingSearchSetting = true;

            let settingsBuffer = [...this.importDataSettings];
            const settingIndex = settingsBuffer.findIndex(importDataSetting => String(importDataSetting?.id) === String(settingId));
            if (settingIndex !== -1) {
                const setting = {
                    ...settingsBuffer[settingIndex],
                    list: [],
                    totalCount: 0,
                    page: 0,
                    search: String(searchValue)
                };

                settingsBuffer.splice(settingIndex, 1, setting);
            }

            this.setImportData('settings', settingsBuffer);

            this.getSetting(settingId);
        },
        async getSetting(settingId = '') {
            const {page = 1, search = '', isCanGet = false} = this.getSettingBefore(settingId);
            if (isCanGet) {
                try {
                    const settingData = await ServiceReconciliationImport.getSetting(settingId, page + 1, search);
                    this.getSettingsAfter([settingData]);
                } catch (error) {
                    console.log(error);
                }
            }
        },
        getSettingBefore(settingId = '') {
            const setting = this.importDataSettings.find(importDataSetting => String(importDataSetting?.id) === String(settingId)) ?? {};
            const isCanGet = setting?.list.length <= setting?.totalCount;
            const params = {
                page: parseInt(setting?.page ?? 1),
                search: String(setting?.search ?? ''),
                isCanGet
            };

            return params;
        },
        async getMarkingValues() {
            try {
                const markingValues = await ServiceReconciliationImport.getMarkingValues(this.importDataTabIdActiveAttach);
                this.setImportData('markingValues', markingValues);
                this.getTemplates();
            } catch (error) {
                console.log(error);
            }
        },
        async getTemplates() {
            try {
                const templates = await ServiceReconciliationImport.getTemplates('import', this.importDataMarkingValues);
                this.setImportData('templates', templates);
                this.updateTemplateAfter();
            } catch (error) {
                console.log(error);
            }
        },
        async runMatch(isBufferData = false) {
            try {
                if (!isBufferData)
                    this.importDataTableRowsMarkingSelectedBuffer = [ ...this.importDataTableRowsMarkingSelected ];

                this.isLoading = true;
                await ServiceReconciliationImport.runMatch(
                    this.importDataFileId,
                    this.importDataTabIdActive,
                    this.importDataTabIdActiveAttach,
                    // this.importDataTableRowsMarkingSelected,
                    this.importDataTableRowsMarkingSelectedBuffer,
                    this.importDataSettingsListIdsActive,
                    this.importDataFileStartIndex
                );
            } catch (error) {
                console.log(error);
                // alert(error);
                this.$notify.dNotify({
                    type: 'error',
                    title: 'Ошибка разметки шаблона',
                    description: error,
                    duration: Infinity,
                    position: 'bottom-right'
                });

                this.toPrevStep();
                this.isLoading = false;
            }
        },
        async getTableDownload() {
            try {
                this.isLoading = true;
                const tableData = await ServiceReconciliationImport.getTableDownload(
                    this.importDataTabListIds,
                    this.importDataFileId,
                    this.importDataTabIdActiveMarking,
                    // this.importDataTableRowsMarkingSelected,
                    this.importDataTableRowsMarkingSelectedBuffer,
                    this.importDataMarkingValues
                );
                ['tableColumns', 'tableRows', 'tableRowsTotalCount'].forEach(importDataKey => this.setImportData(importDataKey, tableData[importDataKey] ?? null));
                this.isLoading = false;

            } catch (error) {
                console.log(error);
                this.isLoading = false;
            }
        },
        async downloadMatch() {
            try {
                await ServiceReconciliationImport.downloadMatch(this.importDataFileId, this.importDataTabIdActiveMarking);

                this.onCancel();
            } catch (error) {
                console.log(error);

                this.$notify.dNotify({
                    type: 'error',
                    title: 'Ошибка загрузки файла',
                    description: error.message,
                    duration: Infinity,
                    position: 'bottom-right'
                });
            }
        },
        endMatch(success = false) {
            this.isLoading = false;

            if (success)
                this.getTableDownload();
            else {
                // alert('Что-то пошло не так при разметке');

                this.$notify.dNotify({
                    type: 'error',
                    title: 'Ошибка разметки шаблона',
                    description: 'Что-то пошло не так при разметке',
                    duration: Infinity,
                    position: 'bottom-right'
                });

                this.toPrevStep();
            }

        },
        getSettingsListIdActive(settingKey = '') {
            const setting = this.importDataSettingsListIdsActive.find(setting => String(setting?.id ?? '') === String(settingKey));
            return String(setting?.listIdActive ?? '');
        },
        onClickTab(tabId = '') {
            this.setImportData('tabIdActive', String(tabId));

            const fileDataAfter = ServiceReconciliationImport._attachFileAfter(this.importDataFileExtraData, this.importDataFile, this.importDataFileStartIndexColumn, this.importDataFileStartIndex);
            this.attachFileAfter(fileDataAfter, false);
            
            if (this.importStep !== 'download')
                this.getMarkingValues();
        },
        async onDeleteTemplate(templateId = '') {
            await ServiceReconciliationImport.deleteTemplate(templateId);
            
            const templatesBuff = this.importDataTemplates.filter(template => String(template?.id ?? '') !== String(templateId));
            const templateIdActive = String(templatesBuff[0]?.id ?? '');
            this.setImportData('templates', [...templatesBuff]);
            
            if (templateIdActive)
                this.onSelectTemplate(templateIdActive);

            this.$notify.dNotify({
                type: 'success',
                title: 'Изменение шаблона',
                description: 'Шаблон успешно удален',
                duration: Infinity,
                position: 'bottom-right'
            });
        },
        async onSaveTemplateEditable(templateId = '', templateNameEditable = '') {
            const templateAdditingParams = { template_id: String(templateId) };
            const template = await this.updateTemplate('update', templateNameEditable, templateAdditingParams);
            
            if (template)
                this.onSaveTemplateAfter(template);
        },
        async onSelectTemplate(templateId = '') {
            const isResponse = await ServiceReconciliationImport.setTemplateLastUpdate(templateId);

            if (isResponse) {
                this.selectTemplateAfter(this.importDataTemplateIdActive, 0);
                this.selectTemplateAfter(templateId, 1);

                // this.setImportData('templateIdActive', templateId);
                this.onClearSelected();
                this.updateTemplateAfter();
            }
        },
        selectTemplateAfter(templateId = '', isLastUsed = -1) {
            let templatesBuff = [...this.importDataTemplates];
            const templateBuffIndex = templatesBuff.findIndex(templateBuff => String(templateBuff?.id ?? '') === String(templateId));

            if (templateBuffIndex !== -1) {
                templatesBuff[templateBuffIndex].extraData.is_last_used = parseInt(isLastUsed);
                this.setImportData('templates', [...templatesBuff]);
            }
        },
        async onSaveTemplate() {
            // const actionType = this.importDataTemplates.length > 0 ? 'update' : 'create';
            // try {
            //     const template = await ServiceReconciliationImport.setTemplate({
            //         templateName: 'Шаблон',
            //         templateAdditingParams: actionType === 'create' ? {type: 'import'} : {template_id: String(this.importDataTemplates[0]?.id ?? '')},
            //         rowsMarkingSelected: this.importDataTableRowsMarkingSelected,
            //         settingsListIdsActive: this.importDataSettingsListIdsActive,
            //         startRowIndex: 0,
            //         markingValues: this.importDataMarkingValues,
            //         actionType: actionType
            //     });
            //     this.setImportData('templates', [template]);
            // } catch (error) {
            //     console.log(error);
            // }
            if (this.importDataDates.length === 0)
                this.throwErrorMarkingDates();
            else {
                const actionType = 'update';
                const templateName = String(this.importDataTemplateActive?.extraData?.name ?? '');
                const templateAdditingParams = { template_id: String(this.importDataTemplates[0]?.id ?? '') };
                const template = await this.updateTemplate(actionType, templateName, templateAdditingParams);
    
                if (template)
                    this.onSaveTemplateAfter(template);
            }
        },
        onSaveTemplateAfter(template) {
            const templatesBuff = [...this.importDataTemplates];
            const templateBuffIndex = templatesBuff.findIndex(templateBuff => String(templateBuff?.id ?? '') === String(template?.id ?? ''));

            if (templateBuffIndex !== -1)
                templatesBuff.splice(templateBuffIndex, 1, template);

            this.setImportData('templates', [...templatesBuff]);

            this.$notify.dNotify({
                type: 'success',
                title: 'Изменение шаблона',
                description: 'Шаблон успешно изменен',
                duration: Infinity,
                position: 'bottom-right'
            });
        },
        async onCreateTemplate() {
            if (this.importDataDates.length === 0)
                this.throwErrorMarkingDates();
            else {
                const actionType = 'create';
                const templateName = `Шаблон ${this.importDataTemplates.length + 1}`;
                const templateAdditingParams = { type: 'import' };
                const template = await this.updateTemplate(actionType, templateName, templateAdditingParams);
    
                if (template) {
                    this.setImportData('templates', [...this.importDataTemplates, template]);
                    this.onSelectTemplate(String(template?.id ?? ''));
    
                    this.$notify.dNotify({
                        type: 'success',
                        title: 'Создание шаблона',
                        description: 'Шаблон успешно создан',
                        duration: Infinity,
                        position: 'bottom-right'
                    });
                }
            }
        },
        async updateTemplate(actionType = '', templateName = '', templateAdditingParams = {}) {
            try {
                const template = await ServiceReconciliationImport.setTemplate({
                    // templateName: 'Шаблон',
                    // templateAdditingParams: actionType === 'create' ? {type: 'import'} : {template_id: String(this.importDataTemplates[0]?.id ?? '')},
                    rowsMarkingSelected: this.importDataTableRowsMarkingSelected,
                    settingsListIdsActive: this.importDataSettingsListIdsActive,
                    startRowIndex: 0,
                    markingValues: this.importDataMarkingValues,
                    actionType,
                    templateName,
                    templateAdditingParams
                });
                // this.setImportData('templates', [template]);
                return template;
            } catch (error) {
                console.log(error);
                return null;
            }
        },
        onSelectSetting(listIdActive = '', settingId = '') {
            let settingsBuffer = [...this.importDataSettings];
            const settingsBufferIndex = settingsBuffer.findIndex((setting) => String(setting?.id ?? '') === String(settingId));
            const settingNew = {...settingsBuffer[settingsBufferIndex], listIdActive: String(listIdActive)};
            settingsBuffer.splice(settingsBufferIndex, 1, settingNew);

            this.setImportData('settings', [...settingsBuffer]);
        },
        onCancel() {
            this.$emit('onCancel');
        },
        onSelectItemsTable(rowId = '', itemsSelected = []) {
            let importDataTableRowsBuffer = [...this.importDataTableRows];
            const importDataTableRowsIndex = importDataTableRowsBuffer.findIndex(row => String(row?.id) === String(rowId));
            const importDataTableRowNew = {
                ...importDataTableRowsBuffer[importDataTableRowsIndex],
                // markingValue: [...itemsSelected]
                markingValue: itemsSelected.map(markingValueItem => {
                    if (markingValueItem?.type === 'multi')
                        markingValueItem.dates = [...this.importDataDates];
                    
                    return markingValueItem;
                })
            };
            importDataTableRowsBuffer.splice(importDataTableRowsIndex, 1, importDataTableRowNew);
            const importDataTableRowsNew = {
                ...this.importDataByStep?.tableRows,
                [this.importDataTabIdActive]: [...importDataTableRowsBuffer]
            };

            this.setImportData('tableRows', importDataTableRowsNew);

        },
        onCheckDates(dates = []) {
            this.setImportData('dates', dates);

            let importDataTableRowsBuffer = [...this.importDataTableRows].map(tableRow => {
                tableRow.markingValue.map(markingValueItem => {
                    if (markingValueItem?.type === 'multi')
                        markingValueItem.dates = [...dates];
                    
                    return markingValueItem;
                });

                return tableRow;
            });
            const importDataTableRowsNew = {
                ...this.importDataByStep?.tableRows,
                [this.importDataTabIdActive]: [...importDataTableRowsBuffer]
            };

            this.setImportData('tableRows', importDataTableRowsNew);
        },
        toNextStep() {
            if (this.isImportStepMap.download && this.importDataTabIdActiveAttach === 'approved') {
                this.downloadMatch();
                // this.onCancel();
            }
            else if(this.isImportStepMap.download && this.importDataTabIdActiveAttach === 'reconciliation'){
                this.onCancel();
                document.location.reload();
            }
            else {
                if (this.isImportStepMap.marking){
                    if (this.importDataDates.length === 0)
                        this.throwErrorMarkingDates();
                    else {
                        this.runMatch();
                        this.importStepIndex += 1;
                    }
                } else
                    this.importStepIndex += 1;
            }
        },
        toPrevStep() {
            if (this.isImportStepMap.download) {
                this.setImportData('tableColumns', []);
                this.setImportData('tableRows', {});
            }
            this.importStepIndex -= 1;
        },
        updateTemplateAfter() {
            // const template = this.importDataTemplates[0] ?? {};
            const template = this.importDataTemplates.find(template => parseInt(template?.extraData?.is_last_used ?? -1) === 1) ?? {};
            const {rowsMarkingValue = {}, settingsValue = {}, id = ''} = template;
            Object.keys(rowsMarkingValue).forEach(rowId => this.onSelectItemsTable(String(rowId), rowsMarkingValue[rowId] ?? []));
            Object.keys(settingsValue).forEach(settingKey => this.onSelectSetting(String(settingsValue[settingKey]), String(settingKey)));
            this.setImportData('templateIdActive', String(id));
        },
        onClearSelected() {
            this.importDataTableRowsIds.forEach(rowId => this.onSelectItemsTable(String(rowId), []));
            this.importDataSettingsIds.forEach(settingId => this.onSelectSetting('', String(settingId)));
        },
        onToggleSelectModal(isOpen = false) {
            this.$emit('onToggleSelectModal', isOpen);
        },
        onRunMatch() {
            this.toPrevStep();
            // setTimeout(() => this.toNextStep(), 600);
            // this.toNextStep();

            this.runMatch(true);
            this.importStepIndex += 1;
        },
        throwErrorMarkingDates() {
            this.$notify.dNotify({
                type: 'error',
                title: 'Ошибка разметки шаблона',
                description: 'Выберите дату',
                duration: Infinity,
                position: 'bottom-right'
            });
        },
        onEditCellImportDownload(cellEditData = {}) {
            this.rowEditMatch(cellEditData);
        },
        async rowEditMatch(cellEditData = {}) {
            const rowId = String(cellEditData?.tableData?.row?.id ?? '');
            const columnProp = String(cellEditData?.tableData?.column?.prop ?? '');
            const cellValue = String(cellEditData?.cellValue ?? '');
            
            const tableRow = await ServiceReconciliationImport.rowEditMatch(
                this.importDataFileId, 
                this.importDataTabIdActiveMarking, 
                rowId, 
                columnProp, 
                cellValue, 
                this.importDataTableColumns
            );

            this.rowEditMatchAfter(tableRow);
        },
        rowEditMatchAfter(tableRow = {}) {
            let tableRowsBuff = [...this.importDataTableRows];
            const tableRowBuffIndex = tableRowsBuff.findIndex(tableRowBuff => String(tableRowBuff?.id ?? '') === String(tableRow?.id ?? ''));

            if (tableRowBuffIndex !== -1) {
                tableRowsBuff[tableRowBuffIndex] = tableRow;
                const importDataTableRowsNew = {
                    ...this.importDataByStep?.tableRows,
                    [this.importDataTabIdActive]: [...tableRowsBuff]
                };

                this.setImportData('tableRows', importDataTableRowsNew);
            }
        },
        onRemoveRowImportDownload(rowId = '', tabIdActive = '') {
            this.removeRowImportDownload(rowId, tabIdActive);
        },
        async removeRowImportDownload(rowId = '', tabIdActive = '') {
            const isRemoved = await ServiceReconciliationImport.rowRemoveMatch(this.importDataFileId, this.importDataTabIdActiveMarking, rowId);

            if (isRemoved)
                this.removeRowImportDownloadAfter(rowId, tabIdActive)
        },
        removeRowImportDownloadAfter(rowId = '', tabIdActive = '') {
            let tableRowsBuff = [...this.importDataTableRows];
            const tableRowBuffIndex = tableRowsBuff.findIndex(tableRowBuff => String(tableRowBuff?.id ?? '') === String(rowId));

            if (tableRowBuffIndex !== -1) {
                tableRowsBuff.splice(tableRowBuffIndex, 1);
                const importDataTableRowsNew = {
                    ...this.importDataByStep?.tableRows,
                    [this.importDataTabIdActive]: [...tableRowsBuff]
                };
                const tableRowsTotalCountNew = {
                    ...this.importDataTableRowsTotalCount,
                    [tabIdActive]: parseInt(this.importDataTableRowsTotalCount[tabIdActive] ?? 1) - 1
                };

                this.setImportData('tableRows', importDataTableRowsNew);
                this.setImportData('tableRowsTotalCount', tableRowsTotalCountNew);
            }
        },
        async onSetDifferenceImportDownload(difference = {}) {
            const rowId = String(difference?.rowId ?? '');
            const differenceType = String(difference?.differenceType ?? '');
            const attribute = String(difference?.differenceData?.attribute ?? '');
            const uuid = String(difference?.differenceData?.id ?? '');

            let tableRowsBuff = [...this.importDataTableRows];
            const tableRowBuffIndex = tableRowsBuff.findIndex(tableRowBuff => String(tableRowBuff?.id ?? '') === rowId);

            if (tableRowBuffIndex !== -1) {
                let tableRowBuffNew = { ...tableRowsBuff[tableRowBuffIndex] };
                tableRowBuffNew[difference?.columnProp].difference.differenceSelected = differenceType;
                tableRowsBuff.splice(tableRowBuffIndex, 1, tableRowBuffNew);

                const importDataTableRowsNew = {
                    ...this.importDataByStep?.tableRows,
                    [this.importDataTabIdActive]: [...tableRowsBuff]
                };
                this.setImportData('tableRows', importDataTableRowsNew);
            }
            
            this.acceptDifferenceImportDownload(rowId, differenceType, attribute, uuid);
        },
        async acceptDifferenceImportDownload(rowId = '', differenceType = '', attribute = '', uuid = '') {
            await ServiceReconciliationMatch.acceptDifference(this.importDataFileId, this.importDataTabIdActiveMarking, rowId, differenceType, attribute, uuid);
        },
        onUpdateFileStartIndex(startIndex = 0) {
            this.setImportData('fileStartIndex', startIndex - 1);
            const fileDataAfter = ServiceReconciliationImport._attachFileAfter(this.importDataFileExtraData, this.importDataFile, this.importDataFileStartIndexColumn, startIndex - 1);
            this.attachFileAfter(fileDataAfter, false);
            this.getMarkingValues();
        },
        onUpdateFileStartIndexColumn(startIndex = 0) {
            this.setImportData('fileStartIndexColumn', startIndex - 1);
            const fileDataAfter = ServiceReconciliationImport._attachFileAfter(this.importDataFileExtraData, this.importDataFile, startIndex - 1, this.importDataFileStartIndex);
            this.attachFileAfter(fileDataAfter, false);
            this.getMarkingValues();
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-import {
        &__actions {
            display: flex;
            justify-content: space-between;

            &-block {
                display: flex;
            }
        }

        &__action {
            position: relative;
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }
</style>
