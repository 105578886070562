<template>
    <div class="reconciliation-import-marking">
        <div class="reconciliation-import-marking__section">
            <BaseButton
                view="border"
                @click="toPrevStep"
            >
                Назад
            </BaseButton>
        </div>

        <!-- <div class="reconciliation-import-marking__section">
            <ReconciliationInfo :infoText="tabDescription" />
        </div> -->
        <div
            v-if="templatesForSelect.length"
            class="reconciliation-import-marking__section"
        >
            <div class="reconciliation-import-marking__section-item">
                <div class="reconciliation-import-marking__title">
                    Настройки шаблона
                </div>

                <div
                    v-if="!isTemplateEditable"
                    class="reconciliation-import-marking__subtitle"
                >
                    Выберите шаблон
                </div>

                <div
                    v-if="isTemplateEditable"
                    class="reconciliation-import-marking__subtitle"
                >
                    Введите название шаблона
                </div>

                <div class="reconciliation-import-marking__templates">
                    <BaseSelect
                        v-if="!isTemplateEditable && templatesForSelect.length"
                        :options="templatesForSelect"
                        :value="templateIdActive"
                        :canSearch="true"
                        defaultTitle="Выберите из списка" 
                        class="reconciliation-import-marking__select"
                        @check="onSelectTemplate"
                    />

                    <BaseTextInput
                        v-if="isTemplateEditable"
                        :value.sync="templateNameEditable"
                        class="reconciliation-import-marking__input" 
                    />

                    <div class="reconciliation-import-marking__btn-list">
                        <BaseButton
                            v-if="!isTemplateEditable"
                            view="simple"
                            class="reconciliation-import-marking__action-list-item"
                            @click="onEditTemplate"
                        >
                            Изменить
                        </BaseButton>

                        <BaseButton
                            v-if="!isTemplateEditable"
                            view="simple"
                            class="reconciliation-import-marking__action-list-item"
                            @click="onDeleteTemplate"
                        >
                            Удалить
                        </BaseButton>

                        <BaseButton
                            v-if="isTemplateEditable"
                            view="simple"
                            class="reconciliation-import-marking__action-list-item"
                            @click="onSaveTemplateEditable"
                        >
                            Сохранить
                        </BaseButton>

                        <BaseButton
                            v-if="isTemplateEditable"
                            view="simple"
                            class="reconciliation-import-marking__action-list-item"
                            @click="onCancelTemplateEditable"
                        >
                            Отменить
                        </BaseButton>
                    </div>
                </div>
            </div>

            <div class="reconciliation-import-marking__section-item">
                <div class="reconciliation-import-marking__title">
                    Настройки импорта
                </div>

                <div class="reconciliation-import-marking__setting">
                    <div
                        v-for="(setting) in settings"
                        :key="getSettingId(setting)"
                        class="reconciliation-import-marking__setting-item"
                    >

                        <div class="reconciliation-import-marking__subtitle">
                            {{getSettingSubtitle(setting)}}
                        </div>

                        <BaseSelect
                            :options="getSettingList(setting)"
                            :value="getSettingListIdActive(setting)"
                            :canSearch="true"
                            :isVirtualSearch="false"
                            :widthDropdownContent="'210px'"
                            defaultTitle="Выберите из списка" 
                            class="reconciliation-import-marking__select"
                            @check="onSelectSetting($event, setting)"
                            @lazyload="onLazyloadSetting(setting)"
                            @onSearchValue="onSearchSetting($event, setting)"
                        />
                    </div>

                    <div class="reconciliation-import-marking__setting-item">
                        <div class="reconciliation-import-marking__subtitle">
                            Выберите дату
                        </div>

                        <DatePickerDropdown
                            :isMultiple="false"
                            :checkedDates="importDatesToDatepicker"
                            :defaultTitle="'Выберите дату'"
                            class="reconciliation-import-marking__datepicker"
                            @onApprove="onCheckDates"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="reconciliation-import-marking__section">
            <div class="reconciliation-import-marking__section-item">
                <div class="reconciliation-import-marking__title">
                    Разметка данных из файла
                </div>

                <MenuTabList
                    :tabList="tabList"
                    :tabIdActive="tabIdActive"
                    tabMode="default"
                    class="reconciliation-import-marking__menu-tab-list"
                    @onClickTab="onClickTab"
                />
            </div>

            <div class="reconciliation-import-marking__section-item">
                <div class="reconciliation-import-marking__setting">
                    <div class="reconciliation-import-marking__setting-item">
                        <div class="reconciliation-import-marking__subtitle">
                            Номер строки в файле с заголовками
                        </div>
    
                        <BaseCounter
                            :minCount="1"
                            :maxCount="Number.MAX_SAFE_INTEGER"
                            :valueCount="fileStartIndexColumn + 1"
                            @update:valueCount="onUpdateFileStartIndexColumn"
                            class="reconciliation-import-marking__counter"
                        />
                    </div>
    
                    <div class="reconciliation-import-marking__setting-item">
                        <div class="reconciliation-import-marking__subtitle">
                            Номер строки в файле с данными
                        </div>
    
                        <BaseCounter
                            :minCount="fileStartIndexColumn + 2"
                            :maxCount="Number.MAX_SAFE_INTEGER"
                            :valueCount="fileStartIndex + 1"
                            @update:valueCount="onUpdateFileStartIndex"
                            class="reconciliation-import-marking__counter"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="reconciliation-import-marking__section">
            <div class="reconciliation-import-marking__setting-item">
                <div class="reconciliation-import-marking__action-list">
                    <BaseButton
                        view="simple"
                        class="reconciliation-import-marking__action-list-item"
                        @click="onClearSelected"
                    >
                        Очистить весь выбор данных
                    </BaseButton>
                    
                    <BaseButton
                        view="simple"
                        class="reconciliation-import-marking__action-list-item"
                        @click="onPreview"
                    >
                        Предпросмотр листа
                    </BaseButton>
                </div>

                <ReconciliationImportMarkingTable 
                    :columns="tableColumns"
                    :rows="tableRows"
                    :markingValues="markingValues"
                    class="reconciliation-import-marking__table"
                    @onSelectItemsTable="onSelectItemsTable"
                    @onToggleSelectModal="onToggleSelectModal"
                />
            </div>
        </div>

    </div>
</template>

<script>
import MenuTabList from '../../MenuTabList/MenuTabList.vue';
// import ReconciliationInfo from './ReconciliationInfo.vue';
import ReconciliationImportMarkingTable from './ReconciliationImportMarkingTable.vue';
import BaseButton from '../../Base/BaseButton.vue';
import BaseSelect from '../../Base/BaseSelect.vue';
import BaseCounter from '../../Base/BaseCounter.vue';
import BaseTextInput from "@/components/Base/BaseTextInput.vue";
import DatePickerDropdown from '../../DatePickerDropdown.vue';
import UtilDate from '../../../utils/UtilDate.js';

export default {
    name: 'ReconciliationImportMarking',
    components: {
        MenuTabList,
        // ReconciliationInfo,
        ReconciliationImportMarkingTable,
        BaseButton,
        BaseSelect,
        BaseCounter,
        BaseTextInput,
        DatePickerDropdown
    },
    props: {
        templates: {
            type: Array,
            default: () => ([])
        },
        templateIdActive: {
            type: String,
            default: ''
        },
        settings: {
            type: Array,
            default: () => ([])
        },
        tabList: {
            type: Array,
            default: () => ([])
        },
        tabIdActive: {
            type: String,
            default: ''
        },
        tabDescription: {
            type: String,
            default: ''
        },
        importDates: {
            type: Array,
            default: () => ([])
        },
        tableColumns: {
            type: Array,
            default: () => ([])
        },
        tableRows: {
            type: Array,
            default: () => ([])
        },
        markingValues: {
            type: Array,
            default: () => ([])
        },
        fileId: {
            type: String,
            default: ''
        },
        fileStartIndex: {
            type: Number,
            default: 0
        },
        fileStartIndexColumn: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        isTemplateEditable: false,
        templateNameEditable: '',
    }),
    computed: {
        templatesForSelect() {
            return this.templates.map(template => ({ ...template, value: String(template?.extraData?.name ?? '') }));
        },
        templateNameActive() {
            return this.templates.find(template => String(template?.id ?? '') === this.templateIdActive)?.extraData?.name ?? '';
        },
        importDatesToDatepicker() {
            return this.importDates.map(date => UtilDate.toDatepickerDate(date));
        },
    },
    created() {
		this.$parent.$on('$scrollReconciliationModalImport', () => this.$emit('$scrollReconciliationModalImport'));
	},
    methods: {
        toPrevStep() {
            this.$emit('toPrevStep')
        },
        onSelectTemplate(template = {}) {
            this.$emit('onSelectTemplate', String(template?.id ?? ''));
        },
        onEditTemplate() {
            this.isTemplateEditable = true;
            this.templateNameEditable = this.templateNameActive;
        },
        onCancelTemplateEditable() {
            this.isTemplateEditable = false;
            this.templateNameEditable = '';
        },
        onSaveTemplateEditable() {
            if (this.templateNameEditable !== '' && this.templateNameEditable !== this.templateNameActive)
                this.$emit('saveTemplateEditable', this.templateIdActive, this.templateNameEditable);

            this.onCancelTemplateEditable();
        },
        onDeleteTemplate() {
            this.$emit('deleteTemplate', this.templateIdActive);
        },
        onSelectSetting(settingListItem = {}, setting = {}) {
            this.$emit('onSelectSetting', String(settingListItem?.id ?? ''), String(setting?.id ?? ''));
        },
        onLazyloadSetting(setting = {}) {
            this.$emit('onLazyloadSetting', String(setting?.id));
        },
        onSearchSetting(searchValue = '', setting = {}) {
            this.$emit('onSearchSetting', searchValue, String(setting?.id ?? ''));
        },
        onSelectItemsTable(rowId = '', itemsSelected = []) {
            this.$emit('onSelectItemsTable', rowId, itemsSelected);

        },
        getSettingId(setting = {}) {
            return String(setting?.id ?? '')
        },
        getSettingSubtitle(setting = {}) {
            return String(setting?.subtitle ?? '');
        },
        getSettingList(setting = {}) {
            return setting?.list ?? [];
        },
        getSettingListIdActive(setting = {}) {
            return String(setting?.listIdActive ?? '');
        },
        onClickTab(tabId = '') {
            this.$emit('onClickTab', tabId);
        },
        onClearSelected() {
            this.$emit('onClearSelected');
        },
        onToggleSelectModal(isOpen = false) {
            this.$emit('onToggleSelectModal', isOpen);
        },
        onPreview() {
            const routeData = this.$router.resolve({ 
                name: 'reconciliationPreview',
                query: { 
                    fileId: this.fileId, 
                    sheetId: this.tabIdActive
                }
            });
            window.open(routeData.href, '_blank');
        },
        onCheckDates(dates = []) {
            const datesFromDatepicker = dates.map(date => UtilDate.fromDatepickerDate(date));
            
            this.$emit('onCheckDates', datesFromDatepicker);
        },
        onUpdateFileStartIndex(startIndex = 0) {
            this.$emit('updateFileStartIndex', startIndex);
        },
        onUpdateFileStartIndexColumn(startIndex = 0) {
            this.$emit('updateFileStartIndexColumn', startIndex);
        }
    }
}
</script>

<style lang="scss">
.reconciliation-import-marking__input .input {
    border: none !important;
}
</style>

<style lang="scss" scoped>
    .reconciliation-import-marking {
        &__section {
            display: flex;
            margin-bottom: 20px;

            &-item {
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }

        &__title {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 10px;
        }

        &__subtitle {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            margin-bottom: 15px;
        }

        &__select {
            width: 210px;
        }

        &__input {
            padding: 10px 15px;
            border-radius: 4px;
            background: #f5f5f5;
            width: 210px;
        }

        &__setting {
            display: flex;

            &-item {
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }

        &__table {
            margin-top: 10px;
            max-height: calc(100vh - 500px);
            overflow-x: hidden;
        }

        &__menu-tab-list {
            margin-bottom: 10px;
        }

        &__action-list {
            display: flex;

            &-item {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        &__templates {
            display: inline-block;
        }

        &__btn-list {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
        }

        &__datepicker {
            width: 210px;
            height: 38px;
        }

        &__counter {
            margin-bottom: 10px;
        }
    }
</style>